/*This is Trai Tortis Portfolio 2022*/

import React from "react";
import "./Portfolio.css";
import IMG1 from "../../assets/machineLearning.jpg";
import IMG2 from "../../assets/uberAppImage.jpg";
import IMG3 from "../../assets/apple.jpg";
import IMG4 from "../../assets/relatedWordsImage.jpg";
import IMG5 from "../../assets/imageCaptcha.png";
import IMG6 from "../../assets/messengerApp.png";

const data = [
  {
    id: 1,
    image: IMG6,
    title: "Messenger App",
    github: "https://github.com/darthjeditt/TraisMessengerApp",
    demo: "https://github.com/darthjeditt/TraisMessengerApp",
  },
  {
    id: 2,
    image: IMG2,
    title: "Uber App Replica",
    github: "https://github.com/darthjeditt/uber-app-replica",
    demo: "https://github.com/darthjeditt/uber-app-replica",
  },
  {
    id: 3,
    image: IMG3,
    title: "Auto Install Most Downloaded PyPI Packages",
    github: "https://github.com/darthjeditt/top-PyPI-Packages-Auto-Install",
    demo: "https://github.com/darthjeditt/top-PyPI-Packages-Auto-Install",
  },
  {
    id: 4,
    image: IMG4,
    title: "Word2Vec Machine learning Project",
    github: "https://github.com/darthjeditt/Related-Words",
    demo: "https://github.com/darthjeditt/Related-Words",
  },
  {
    id: 5,
    image: IMG5,
    title: "Machine-Learning-OCR-Model-Reading-Captchas",
    github:
      "https://github.com/darthjeditt/Machine-Learning-OCR-Model-Reading-Captchas",
    demo: "https://github.com/darthjeditt/Machine-Learning-OCR-Model-Reading-Captchas",
  },
  {
    id: 6,
    image: IMG1,
    title: "Keras Model Machine Learning",
    github: "https://github.com/darthjeditt/Machine-Learning-Keras-Model",
    demo: "https://github.com/darthjeditt/Machine-Learning-Keras-Model",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a
                  href={github}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click Here!
                </a>
                {/* <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a> */}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;

/*This is Trai Tortis Portfolio 2022*/

import React from "react";
import "./Career.css";
import { FcCheckmark } from "react-icons/fc";

const Experience = () => {
  return (
    <section id="career">
      <h5>Highlights of my Career</h5>
      <h2>Career & Education</h2>

      <div className="container career__container">
        {/* FRONTEND EXPERIENCE */}
        <div className="career__frontend">
          <h3>Career</h3>
          <div className="career__content">
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>Freelance (2023 - Present)</h4>
                <small className="text-light">
                  Python, C#, JavaScript, React.js, Node.js, MongoDB
                </small>
              </div>
            </article>
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>PROXI VP – Pipeline TD/Fullstack Dev (2021 - 2023)</h4>
                <small className="text-light">
                  Python, C#, JavaScript, AWS (Beginner)
                </small>
              </div>
            </article>
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>Mobile App Internship (2020 - 2021)</h4>
                <small className="text-light">
                  JavaScript, Android Studios, CSS
                </small>
              </div>
            </article>
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>App Development Project - QUT (2018 - 2019)</h4>
                <small className="text-light">
                  JavaScript, Android Studios, CSS, Tensorflow
                </small>
              </div>
            </article>
          </div>
        </div>

        {/* BACKEND EXPERIENCE */}
        <div className="career__backend">
          <h3>Education</h3>
          <div className="career__content">
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>Bachelor of IT - Comp Science (2015 - 2020)</h4>
                <small className="text-light">
                  C, C#, C++, Python, JavaScript, F#, Networking, Cloud
                  Computing, HTML, CSS
                </small>
              </div>
            </article>
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>Diploma in Software (2014)</h4>
                <small className="text-light">
                  HTML, CSS, JavaScript, MySQL
                </small>
              </div>
            </article>
            <article className="career__details">
              <FcCheckmark className="career__details-icon" />
              <div>
                <h4>CERT lll - IT (2013)</h4>
                <small className="text-light">HTML, CSS, JavaScript</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;

/*This is Trai Tortis Portfolio 2022*/

import React from 'react'
import './Footer.css'
import {ImFacebook, ImLinkedin} from 'react-icons/im'
import {RiInstagramFill} from 'react-icons/ri'

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Back to the top</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#career'>Career</a></li>
        <li><a href='#portfolio'>Projects</a></li>
        <li><a href='#contact'>Contact Me</a></li>
      </ul>

      <div className="footer__socials">
        <a href='https://m.me/trai.torti/'><ImFacebook/></a>
        <a href='https://www.instagram.com/traiflip/'><RiInstagramFill/></a>
        <a href='https://www.linkedin.com/in/trai-torti-7980341a2/'><ImLinkedin/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Trai Torti's Portfolio. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer
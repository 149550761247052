/*This is Trai Tortis Portfolio 2022*/

import React from 'react'
import './About.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {IoLibraryOutline} from 'react-icons/io5'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About Image'/>

          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>2.5+ year working Experience</small>
          </article>

          <article className='about__card'>
            <IoLibraryOutline className='about__icon'/>
            <h5>Projects</h5>
            <small>Complete 10+ projects</small>
          </article>
          </div>
          <p>
          Hello there! My name is Trai, and I'd love to share a bit about myself. Having lived in both China and Korea, I have a solid understanding of both languages.
          I thrive on challenges and possess a natural curiosity about the intricacies of how and why things happen.
          My strong work ethic drives me to continuously learn and strive for excellence, enabling me to deliver the best possible results. 
          As a developer, I find immense joy in crafting exceptional tools and programs that accomplish remarkable feats. 
          Alongside my passion for technology, I am an active individual who enjoys being around people, as I naturally gravitate towards social interactions. 
          While I've been commended for my effective communication skills, I persistently push myself every day to further enhance them. 
          If you're interested in exploring my portfolio as a software developer, please feel free to browse through my work.
          </p>

          <a href='#contact' className='btn btn-primary'>Contact Me</a>
        </div>
      </div>
      </section>
  )
}

export default About
import React from 'react'
import './Contact.css'
import {HiOutlineMail} from 'react-icons/hi'
import {BsMessenger} from 'react-icons/bs'
import {MdFacebook} from 'react-icons/md'

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Drop By</h5>
      <h2>Contact Me Here</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <HiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>trai4.t@gmail.com</h5>
            <a href='mailto:trai4.t@gmail.com' target='_blank'>Email me!</a>
          </article>
          <article className='contact__option'>
            <BsMessenger className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>LinkdIn Messenger</h5>
            <a href='https://www.linkedin.com/in/trai-torti-7980341a2/' target='_blank'>Message me on LinkedIn!</a>
          </article>
          <article className='contact__option'>
            <MdFacebook className='contact__option-icon'/>
            <h4>Facebook</h4>
            <h5>Facebook Messenger</h5>
            <a href='https://m.me/trai.torti/' target='_blank'>Message me on Facebook Messenger!</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact